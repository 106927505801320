<template>
  <v-container>
    <v-row>
      <v-col>
        <v-select
        :items="years"
        v-model="year"
        label="Jaar"
      ></v-select>
      </v-col>
      <v-col>
        <v-select
        :items="quarters"
        v-model="quarter"
        label="Kwartaal"
      ></v-select>
      </v-col>
      <v-col>
        <v-select
        :items="months"
        item-text="text"
        item-value="value"
        v-model="month"
        label="Maand"
      ></v-select>
      </v-col>
      
      <v-col>
        <v-btn-toggle>
        <v-btn color="primary" @click="reset">Reset</v-btn>
        <v-btn color="primary" @click="fetchData">Verzend</v-btn>        
        <v-btn color="primary" @click="print">Afdrukken</v-btn>     
        <v-btn color="primary" @click="exportToSnelstart">Stuur naar Snelstart</v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>      
      <v-card>
        <v-card-title>
          ICP
        </v-card-title>
        <div class="d-flex flex-column align-center block">
  <table v-for="object of icpArray" :key="object._id">
    <tr>
      <th>Klantnr</th>
      <th>Naam</th>
      <th>Land</th>
      <th>BTW-nummer</th>
      <th>Bedrag</th>

    </tr>
    <tr>
      <td>{{object._id}}</td>
      <td>{{object.name}}</td>
      <td>{{object.country}}</td>
      <td>{{object.VATNumber}}</td>      
      <td>{{ toCurrency(object.netTotal)}}</td>
    </tr>
  </table>
  <hr>
  <table>
    <tr>
      <td colspan="3"></td>
      <td>Totaal</td>
      <td>{{ toCurrency(icpArray.reduce((acc,cur)=>acc+cur.netTotal,0))}}</td>
    </tr>
  </table>
  </div>
      </v-card>
  </v-container>
</template>

<script>
import { config } from "../config/config"
import { errorHandler, fetchGET, successHandler, toCurrency  } from "../js/functions";
export default {
name: "icp",
data(){
    return {
      year: 0,
quarter: 0,
month: 0,
years: [2020,2021,2022,2023],
quarters: [1,2,3,4],
months:[
  {text:"Januari", value:1},
  {text:"Februari", value:2},
  {text:"Maart", value:3},
  {text:"April", value:4},
  {text:"Mei", value:5},
  {text:"Juni", value:6},
  {text:"Juli", value:7},
  {text:"Augustus", value:8},
  {text:"September", value:9},
  {text:"Oktober", value:10},
  {text:"November", value:11},
  {text:"December", value:12},
],
icpArray: [],
response: []
    }
},
methods:{
  async fetchData(){
    try{
    let data = {year: parseInt(this.year),quarter: parseInt(this.quarter),month: parseInt(this.month)}
    let res = await fetchGET("icp",data)
      this.icpArray = res
      this.response = res
    return res
    }catch(e){
      console.error(e)
    }
  },
  async exportToSnelstart(){
    try{
    let invoices = this.response.reduce((acc,cur)=>{
      return acc.concat(cur.invoices)
    },[])
    await fetch(`${config.snelstart.host}/postICP`,{
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(invoices),
      credentials: "include"
    }) 
    successHandler("ICP verzonden naar Snelstart")
    }catch(e){
      errorHandler(e,"ICP niet verzonden")
    }
  },
  print(){
    window.print()
  },
  reset(){
    this.year = 0
    this.quarter = 0
    this.month = 0
    this.icpArray = []
  },
  toCurrency(val){
    return toCurrency(val)
  }
}

}
</script>

<style scoped>
.block {
  background:  white;
}
th,td{
  text-align: left;
  width:200px;
}
</style>